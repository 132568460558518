<template>
  <page-content title="订单详情">

    <div style="width: 50%;float: left">

      <div class="info-block status">{{["待付款","待商家发货","待买家收货","已收货","退款中","已退款"][order.status]}}</div>

      <a-descriptions title="商品信息" size="small" :column="{sm:1}" class="info-block">

        <div>
          <item-goods style="background: #FFF;margin-bottom: 1px" v-for="item in order.goodsList" :item="item"></item-goods>
        </div>

        <a-descriptions-item label="订单金额">￥{{order.price/100}}</a-descriptions-item>
        <a-descriptions-item label="订单编号">{{order.orderId}}</a-descriptions-item>
        <a-descriptions-item label="下单时间">{{order.createdAt}}</a-descriptions-item>
        <a-descriptions-item v-if="order.timePaid" label="付款时间">{{order.timePaid}}</a-descriptions-item>
        <a-descriptions-item v-if="order.timeDelivering" label="发货时间">{{order.timeDelivering}}</a-descriptions-item>
        <a-descriptions-item v-if="order.timeApplyRefund" label="申请退款">{{order.timeApplyRefund}}</a-descriptions-item>
        <a-descriptions-item v-if="order.timeRefunded" label="退款时间">{{order.timeRefunded}}</a-descriptions-item>
        <a-descriptions-item v-if="order.timeFinished" label="确认收货">{{order.timeFinished}}</a-descriptions-item>
        <a-descriptions-item v-if="order.timeCancel" label="取消订单">{{order.timeCancel}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions title="发票信息" size="small" :column="{sm:1}" class="info-block">
        <a-descriptions-item label="抬头">{{fapiao.title}}</a-descriptions-item>
        <a-descriptions-item label="税号">{{fapiao.taxNumber}}</a-descriptions-item>
      </a-descriptions>


      <a-descriptions title="退款申请" size="small" :column="{sm:1}" class="info-block" v-if="refund.price">
        <a-descriptions-item label="退款金额">￥{{refund.price/100}}</a-descriptions-item>
        <a-descriptions-item label="备注">{{refund.content}}</a-descriptions-item>
        <a-descriptions-item label="图片">

          <img style="width: 60px;height: 60px;margin-right:10px" v-for="img in refund.images" :src="img"/>

        </a-descriptions-item>
      </a-descriptions>

    </div>

    <div style="width: 50%;float: right">
      <a-descriptions title="" size="small" :column="{sm:1}" class="info-block">
        <a-descriptions-item label="收件人">{{address.userName}}</a-descriptions-item>
        <a-descriptions-item label="手机号">{{address.telNumber}}</a-descriptions-item>
        <a-descriptions-item label="地址">{{address.provinceName}} {{address.cityName}} {{address.countyName}} {{address.detailInfo}}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions title="快递信息" size="small" :column="{sm:1}" class="info-block">
        <a-descriptions-item label="快递公司">{{express.company}}</a-descriptions-item>
        <a-descriptions-item label="快递编号">{{express.number}}</a-descriptions-item>
      </a-descriptions>

      <div class="info-block" v-if="order.routeList && order.routeList.length">
        <a-steps progress-dot :current="0" direction="vertical"  size="small">
          <a-step v-for="route in order.routeList" :title="route.acceptTime" :description="route.remark" />
        </a-steps>
      </div>


    </div>





  </page-content>
</template>

<script>
  import ItemGoods from "../_components/item-goods"
    export default {
    components:{ItemGoods},
      data(){
        return {
          order:{goodsList:[]},
          address:{},
          express:{},
          fapiao:{},
          refund:{}
        }
      },

      created() {
          let id = this.$route.params.id
          this.$get("web/mall/order/"+id).then(suc=>{
            this.order=suc.data
            this.address = JSON.parse(this.order.addressJson) ||{}
            this.fapiao = JSON.parse(this.order.fapiaoJson) ||{}
            this.express = JSON.parse(this.order.expressJson) ||{}
            this.refund = JSON.parse(this.order.refundJson) || {}
          })
        }
    }
</script>

<style lang="less" scoped>

  .info-block{
    margin-bottom: 30px;
    margin-right: 30px;
    background: #F8F8F8;
    padding: 20px;
    border-radius: 5px;
  }

  .ant-steps-vertical{
    width: 100% !important;
  }

  .ant-steps-item{
    width: 100% !important;
  }

  .ant-steps-item-content{
    width: 100% !important;
  }

  .status{
    font-weight: 600;
    color: #000;
    border: 1px solid #1890ff;
  }

</style>
